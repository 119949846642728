// extracted by mini-css-extract-plugin
export var p = "ExpandedNGSWorkstation-module--p--pxAP7";
export var h2 = "ExpandedNGSWorkstation-module--h2--AIwxK";
export var desktop_container = "ExpandedNGSWorkstation-module--desktop_container--MlSQn";
export var h4 = "ExpandedNGSWorkstation-module--h4--A1c2q";
export var clean_up_paragraph = "ExpandedNGSWorkstation-module--clean_up_paragraph--XORrX";
export var application_container = "ExpandedNGSWorkstation-module--application_container--KyhBo";
export var container = "ExpandedNGSWorkstation-module--container--d9Ggb";
export var button = "ExpandedNGSWorkstation-module--button--GXDw9";
export var mobile_image = "ExpandedNGSWorkstation-module--mobile_image--OjFpn";
export var image = "ExpandedNGSWorkstation-module--image--l3e0u";
export var badge = "ExpandedNGSWorkstation-module--badge--3MYss";
export var bg_color = "ExpandedNGSWorkstation-module--bg_color--4bQPc";
export var hero_container = "ExpandedNGSWorkstation-module--hero_container--eOD5D";
export var content_container = "ExpandedNGSWorkstation-module--content_container--lIxGa";
export var shop_item_container = "ExpandedNGSWorkstation-module--shop_item_container--UyG6m";
export var resource_span = "ExpandedNGSWorkstation-module--resource_span---CrrG";
export var resource_link_container = "ExpandedNGSWorkstation-module--resource_link_container--2sbhK";
export var icon = "ExpandedNGSWorkstation-module--icon--bIkis";
export var icon_image = "ExpandedNGSWorkstation-module--icon_image--yyh0S";
export var resource_link = "ExpandedNGSWorkstation-module--resource_link--0N9Ev";
export var resource_note_container = "ExpandedNGSWorkstation-module--resource_note_container--0uUZA";
export var bold_style = "ExpandedNGSWorkstation-module--bold_style--hafFt";
export var italic_style = "ExpandedNGSWorkstation-module--italic_style--0K83c";
export var mobile_view = "ExpandedNGSWorkstation-module--mobile_view--NaR8i";
export var desktop_view = "ExpandedNGSWorkstation-module--desktop_view--rCgr0";
export var grid = "ExpandedNGSWorkstation-module--grid--4GXsh";
export var grid_header = "ExpandedNGSWorkstation-module--grid_header--ltXHg";
export var grid_item = "ExpandedNGSWorkstation-module--grid_item--Xra2N";
export var video = "ExpandedNGSWorkstation-module--video--dX-DS";
export var video_container = "ExpandedNGSWorkstation-module--video_container--g2u-C";
export var figure = "ExpandedNGSWorkstation-module--figure--ATHZ8";
export var play_icon = "ExpandedNGSWorkstation-module--play_icon--7sl1Y";
export var video_details = "ExpandedNGSWorkstation-module--video_details--XmogH";
export var application_data_container = "ExpandedNGSWorkstation-module--application_data_container--NDhAA";
export var data_section_header = "ExpandedNGSWorkstation-module--data_section_header--sfeEO";
export var data_section_sub_header = "ExpandedNGSWorkstation-module--data_section_sub_header--4FXzh";
export var figure_text = "ExpandedNGSWorkstation-module--figure_text--3fpmf";
export var figure_text_hint = "ExpandedNGSWorkstation-module--figure_text_hint--6MhB5";
export var application_data_charts = "ExpandedNGSWorkstation-module--application_data_charts--XnXM-";
export var application_data_chart = "ExpandedNGSWorkstation-module--application_data_chart--gERUS";
export var application_data_columns = "ExpandedNGSWorkstation-module--application_data_columns--oO6PK";
export var application_data_column = "ExpandedNGSWorkstation-module--application_data_column--mNGo6";
export var application_data_p = "ExpandedNGSWorkstation-module--application_data_p--V4GWF";
export var faq_container = "ExpandedNGSWorkstation-module--faq_container--FEPm+";
export var services_table = "ExpandedNGSWorkstation-module--services_table--x9h8T";