// @flow

import * as React from 'react'

import * as styles from './ExpandedNGSWorkstation.module.css'

import ImageOverview from './assets/ngs_steps.png'

export default function ApplicationData() {
  return (
    <div className={styles.application_data_container}>
      <h4 className={styles.h4}>Application Data</h4>
      <p className={styles.p}>
        Next-generation sequencing (NGS) workflow includes preparing DNA
        libraries using commercially available and cost-effective DNA
        preparation kits as well as normalization and pooling data on the OT-2.
        We present data optimizing this workflow by using the OT-2 NGS
        workstation and an automated OT-2 protocol helps to improve the
        efficiency for DNA library preparation, normalization and pooling.
      </p>
      <p className={styles.p}>
        Demonstrated Method for DNA preparation on the OT-2 NGS Workstation
      </p>
      <img src={ImageOverview} />
      <p className={styles.figure_text}>
        Figure 1. NGS with OT-2.{' '}
        <span className={styles.figure_text_hint}>
          The dotted line box indicate the NGS steps that can be automated on
          the OT-2
        </span>
      </p>
    </div>
  )
}
