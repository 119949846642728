import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ExpandedNGSWorkstation from '../../components/ExpandedNGSWorkstation'

const NGSWorkstationPage = () => (
  <Page>
    <SEO
      title="Expanded NGS Workstation | New Opentrons OT-2 Workstations"
      description="Expanded NGS Workstation"
    />
    <ExpandedNGSWorkstation />
  </Page>
)

export default NGSWorkstationPage
