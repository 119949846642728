// @flow

import * as React from 'react'
import {AppLink} from '../../ui-components'

import * as styles from './ExpandedNGSWorkstation.module.css'
import IconDataAndResults from './assets/icon_data_and_results.svg'
import IconDNA from './assets/icon_dna.svg'
import IconLibraryPrep from './assets/icon_library_prep.svg'
import IconOT2 from './assets/icon_ot2.svg'

export const CONTENT_INFO = (
  <div>
    <p className={styles.p}>
      With the OT-2 NGS Workstation, you can automate your library prep and
      pre-sequencing workflows using any leading reagent system on the market,
      including fragmentation- and tagmentation-based workflows.
    </p>
  </div>
)

export const LINKS = {
  Overview: {
    title: 'Overview',
    scrollId: 'overview',
  },
  WorkstationComponents: {
    title: 'Workstation Components',
    scrollId: 'materials',
  },
  VerificationData: {
    title: 'Demonstrated Method',
    scrollId: 'verification',
  },
  ApplicationNotes: {
    title: 'Application Notes',
    scrollId: 'notes',
  },
  Protocols: {
    title: 'Protocols',
    scrollId: 'protocols',
  },
}

export const MENU_DATA = {
  menuData: {
    links: [
      LINKS.Overview.title,
      LINKS.WorkstationComponents.title,
      LINKS.VerificationData.title,
      LINKS.ApplicationNotes.title,
      LINKS.Protocols.title,
    ],
    linkScrollIds: {
      [LINKS.Overview.title]: LINKS.Overview.scrollId,
      [LINKS.WorkstationComponents.title]: LINKS.WorkstationComponents.scrollId,
      [LINKS.VerificationData.title]: LINKS.VerificationData.scrollId,
      [LINKS.ApplicationNotes.title]: LINKS.ApplicationNotes.scrollId,
      [LINKS.Protocols.title]: LINKS.Protocols.scrollId,
    },
    url: '/cleanupkit',
    workflow: 'Interested in automating your NGS workflow?',
  },
  gtmCategory: 'b-workstation',
  protocolGtmLabel: 'schedule-consultation',
  demoGtmLabel: 'schedule-demo',
}

export const SCROLL_MENU_DATA = [
  LINKS.Overview.scrollId,
  LINKS.WorkstationComponents.scrollId,
  LINKS.VerificationData.scrollId,
  LINKS.ApplicationNotes.scrollId,
  LINKS.Protocols.scrollId,
]

export const NOTE_DATA = [
  {
    note: (
      <AppLink
        url={
          'https://insights.opentrons.com/hubfs/App%20Notes/Automating%20DNA%20Library%20Prep%20Illumina%20KAPA%20NEBNext%20App%20Note.pdf'
        }
      >
        Automated library preparation of small genomes using DNA preparation
        kits (Illumina DNA Prep Kit, KAPA HyperPrep kit, and NEBNext Ultra II
        Kit)
      </AppLink>
    ),
  },
  {
    note: (
      <AppLink
        url={
          'https://insights.opentrons.com/hubfs/Applications/NGS/Automating%20DNA%20Clean-Up%20Size%20Selection%20Omega%20BioTek%20Mag%20Bead%20App%20Note.pdf'
        }
      >
        Fully automated NGS clean up and size selection with Omega Bio-Tek’s
        Mag-Bind TotalPure NGS on the OT-2
      </AppLink>
    ),
  },
  {
    note: (
      <AppLink
        url={
          'https://insights.opentrons.com/hubfs/Applications/COVID/Automated%20Viral%20SARS-CoV-2%20Viral%20RNA%20Extraction%20Opentrons%20Beckman%20RNAdvance%20%20App%20Note.pdf'
        }
      >
        Robust and high-throughput SARS-CoV-2 viral RNA detection, research, and
        sequencing using RNAdvance Viral and the OT-2 platform
      </AppLink>
    ),
  },
]

export const VALIDATION_DATA = (
  <p className={styles.p}>
    This application has been fully tested and scientifically verified by
    Opentrons.
  </p>
)

export const PROTOCOLS_DATA = {
  description: '',
  hideTable: true,
  hideDisclosures: true,
  bulletListDescription:
    'Check out example protocols below, or browse all NGS library prep protocols <a style="color: #006fff; font-weight: 600;" href="https://protocols.opentrons.com/categories/NGS%20Library%20Prep">here</a>',
  bulletItems: [
    {
      title: 'NGS Library Prep with Roche HyperPrep Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-roche-hyperprep',
    },
    {
      title:
        'NGS Library Prep with NEBNext Ultra II DNA Library Prep Kit for Illumina',
      url: 'https://protocols.opentrons.com/protocol/sci-neb-next-ultra',
    },
    {
      title: 'NGS Library Prep with IDT xGEN DNA Library Prep EZ Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-idt-xgen-ez',
    },
    {
      title: 'NGS Library Prep with IDT xGEN DNA Library Prep MC Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-idt-xgen-mc',
    },
    {
      title: 'NGS Library Normalization with IDT xGEN Normalase Module',
      url: 'https://protocols.opentrons.com/protocol/sci-idt-normalase',
    },
    {
      title:
        'NGS Cleanup and Size Selection with Omega Bio-tek Mag-Bind TotalPure NGS',
      url: 'https://protocols.opentrons.com/protocol/omega_biotek_magbind_totalpure_NGS',
    },
    {
      title: 'NGS Cleanup with MACHEREY-NAGEL NucleoMag NGS Clean-Up',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-clean-up',
    },
    {
      title:
        'NGS Size Selection with MACHEREY-NAGEL NucleoMag NGS Double-Size Selection',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-size-select',
    },
  ],
}

export const LABWARE_URL = 'https://labware.opentrons.com/'

export const OVERVIEW_DATA = {
  workflowCompatibility:
    'The OT-2 NGS Workstation can support the following workflow steps:',
  listBulletpoint: [
    'Library Construction',
    'Library & Sample Prep',
    'Bead Clean Ups',
    'Tagmentation & Transformation',
    'Normalization and Pooling',
  ],
}

export const MATERIAL_DESCRIPTION =
  'The OT-2 NGS Workstation includes all the hardware and consumables you need to perform automated NGS library prep. It comes with Opentrons’ protocol development service, which provides you with a customized protocol unique to your NGS assay. You’ll also enjoy free access to the Opentrons Protocol Designer, Python API, and Protocol Library.'

export const MATERIAL_DATA = [
  {
    name: 'OT-2 Liquid Handling Robot',
    imgSrc: require('./assets/ot2_liquid_handling_robot.png').default,
  },
  {
    name: 'Magnetic Module',
    imgSrc: require('./assets/MagneticModule.png').default,
  },
  {
    name: 'Temperature Module',
    imgSrc: require('./assets/temperature_module.png').default,
  },
  {
    name: 'Thermocycler Module',
    imgSrc: require('./assets/thermocycler_module.png').default,
  },
  {
    name: 'P20 Multi-Channel Pipette',
    imgSrc: require('./assets/P20.png').default,
  },
  {
    name: 'P300 Multi-Channel Pipette',
    imgSrc: require('./assets/P300.png').default,
  },
  {
    name: 'Opentrons 20 μl Filter Tips Racks (2 x 9600 tips)',
    imgSrc: require('./assets/7_tips200.png').default,
  },
  {
    name: 'Opentrons 200 μl Filter Tips Racks (2 x9600 tips)',
    imgSrc: require('./assets/7_tips200.png').default,
  },
  {
    name: 'NEST 12-Well Reservoir 15 ml (50)',
    imgSrc: require('./assets/NEST12.png').default,
  },
  {
    name: 'Tough 0.2 mL 96-Well PCR Plate (25)',
    imgSrc: require('./assets/9_tough.png').default,
  },
  {
    name: 'NEST 2.0 mL Microcentrifuge Tube (500)',
    imgSrc: require('./assets/nest_2ml_microcentrifuge_tube.png').default,
  },
  {
    name: 'Custom protocol development (standard turnaround time)',
  },
]

export const WORKFLOW_DESCRIPTION =
  'The Opentrons NGS Workstation can accommodate low- to high-throughput workflows. Below is an overview of an Opentrons NGS workstation assay, information on what the workstations include, an application note featuring this workstation, your protocol development options, and other information you may need for your assay.'

export const WORKFLOW_DATA = [
  {
    imgSrc: IconDNA,
    name: 'Sample processing & library preparation',
    content:
      'DNA and/or RNA extraction, this also includes QC and normalization steps.',
  },
  {
    imgSrc: IconLibraryPrep,
    name: 'Library preparation',
    content:
      'This can be a variety of steps such as PCS, fragmentation, ligation, tagmentation, quantification, quantitation, QC, normalization, and pooling.',
  },
  {
    imgSrc: IconOT2,
    name: 'Wash',
    content:
      'Wash off impurities with a wash &/or strip buffer. Process until the protein of interest is released.',
  },
  {
    imgSrc: IconDataAndResults,
    name: 'Analysis',
    content:
      'The data coming off a sequencer is analyzed to reveal the results.',
  },
]

export const RESOURCE_NOTE_DATA = [
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
    validationIcon: true,
  },
  {
    resource: 'video',
    contentText: 'Magnetic Module',
    contentLink: 'https://vimeo.com/299671341',
    iconSrc: 'video-sample',
  },
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
  {
    resource: 'protocol',
    contentText: 'Pending new protocol',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
]
