// @flow

import * as React from 'react'

import Hero from './Hero'

import {
  Overview,
  StickyMenuDynamic,
  LightBox,
  OpentronsMaterial,
  Notes,
  Validation,
} from '../../ui-components'

import KitImage from './assets/NGS_Workstation_Hero.png'

import {
  OVERVIEW_DATA,
  MATERIAL_DATA,
  MATERIAL_DESCRIPTION,
  SCROLL_MENU_DATA,
  MENU_DATA,
  LINKS,
  CONTENT_INFO,
  PROTOCOLS_DATA,
  NOTE_DATA,
  VALIDATION_DATA,
} from './data'

import * as styles from './ExpandedNGSWorkstation.module.css'
import ApplicationData from './ApplicationData'

import OpentronsProtocols from '../../ui-components/application-page/Protocols'

const videoLink =
  'https://player.vimeo.com/video/667936011?h=49a3c34da1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

export default function ExpandedNGSWorkstation() {
  const [videoOpen, setVideoOpen] = React.useState(false)

  return (
    <>
      <Hero imgSrc={KitImage} mobileImgSrc={KitImage} />
      <div className={styles.application_container}>
        <StickyMenuDynamic
          {...MENU_DATA}
          scrollSpyItems={SCROLL_MENU_DATA}
          resetMenu={true}
          buttonOneText="DOWNLOAD FLYER"
          buttonOneLink="https://insights.opentrons.com/hubfs/Products/Workstations/NGS%20Workstation%20One%20Sheeter.pdf"
        />
        <div className={styles.content_container}>
          <Overview content={CONTENT_INFO} {...OVERVIEW_DATA} />
          <OpentronsMaterial
            title={LINKS.WorkstationComponents.title}
            description={MATERIAL_DESCRIPTION}
            materialItems={MATERIAL_DATA}
            id={LINKS.WorkstationComponents.scrollId}
          />
          <Validation
            content={VALIDATION_DATA}
            id={LINKS.VerificationData.scrollId}
            title={LINKS.VerificationData.title}
            noBorder
          />
          <ApplicationData />
          <Notes
            title={LINKS.ApplicationNotes.title}
            id={LINKS.ApplicationNotes.scrollId}
            noteItems={NOTE_DATA}
          />
          <OpentronsProtocols
            {...PROTOCOLS_DATA}
            title={LINKS.Protocols.title}
            id={LINKS.Protocols.scrollId}
          />
        </div>
        {videoOpen && (
          <LightBox
            videoSrc={videoLink}
            onCloseClick={() => setVideoOpen(false)}
          />
        )}
      </div>
    </>
  )
}
